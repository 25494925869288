<template>
    <div class="app-content">
        <!-- <div style="margin-bottom:20px">
      <el-card class="box-card">
        <b>
          {{title}}
        </b>
      </el-card>
    </div> -->
        <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="top"
        >
            <el-row type="flex" justify="center">
                <el-col :sm="20" :lg="16">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <b>培训活动信息</b>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="20">
                                <el-form-item
                                    label="活动封面"
                                    prop="activityCover"
                                >
                                    <ImageUpload
                                        @input="coverClick"
                                        :value="form.activityCover"
                                        :fileSize="2"
                                        :limit="1"
                                    ></ImageUpload>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="名称" prop="activityName">
                                    <el-input
                                        v-model="form.activityName"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="联系方式" prop="phone">
                                    <el-input
                                        v-model="form.phone"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>

                            <el-col :span="8">
                                <el-form-item label="是否启用" prop="status">
                                    <el-radio-group v-model="form.status">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="地点" prop="address">
                                    <el-input
                                        v-model="form.address"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="简介" prop="introduction">
                                    <el-input
                                        v-model="form.introduction"
                                        placeholder="请输入"
                                        type="textarea"
                                        :rows="4"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card
                        class="box-card"
                        style="margin-top: 10px"
                        v-if="$route.query.id"
                    >
                        <div slot="header" class="clearfix">
                            <el-row
                                type="flex"
                                justify="space-between"
                                :gutter="24"
                            >
                                <el-col :span="16"> <b>报名信息</b></el-col>
                                <el-col :span="8" style="text-align: right">
                                    <el-button
                                        type="primary"
                                        @click="deriveClick"
                                        >导出</el-button
                                    >
                                </el-col>
                            </el-row>
                        </div>
                        <el-table :data="reportData" border style="width: 100%">
                            <el-table-column
                                prop="applyTime"
                                label="报名时间"
                                width="150"
                            >
                            </el-table-column>
                            <el-table-column prop="userName" label="姓名">
                            </el-table-column>
                            <el-table-column prop="unit" label="单位">
                            </el-table-column>
                            <el-table-column prop="userPosition" label="职位">
                            </el-table-column>
                            <el-table-column prop="phone" label="联系方式">
                            </el-table-column>
                        </el-table>
                        <!-- 分页 -->
                        <div>
                            <Pagination
                                v-bind:child-msg="pageparm"
                                @callFather="callFather"
                            ></Pagination>
                        </div>
                    </el-card>
                    <el-card
                        class="box-card bottom-card"
                        style="margin-top: 10px"
                    >
                        <el-row type="flex" justify="end">
                            <!-- <el-button @click="resetForm('ruleForm')">取 消</el-button> -->
                            <el-button
                                type="primary"
                                @click="submitForm('ruleForm')"
                                >提 交</el-button
                            >
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {
    getTrainActivityInfoApi,
    getTrainActivityCreateApi,
    getTrainActivityUpdateApi,
    getTrainActivitySignUpInfoApi,
} from '@/api/training'

export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            title: '新增模板',
            form: {},
            reportData: [],
            rules: {
                activityName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '请输入联系方式',
                        trigger: 'blur',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: '请选择是否启用',
                        trigger: 'blur',
                    },
                ],
                address: [
                    {
                        required: true,
                        message: '请输入地点',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    created() {
        if (this.$route.query.id) {
            this.getTrainActivityInfoFun()
            this.getTrainActivitySignUpInfoFun()
        }
    },
    methods: {
        // 获取报告详情
        async getTrainActivitySignUpInfoFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: pageSize ? pageSize : 10,
                draw: 1,
                activityId: this.$route.query.id,
            }
            var res = await getTrainActivitySignUpInfoApi(params)
            this.reportData = res.data
            this.pageparm.total = res.recordsTotal
            // console.log('报告详情', res);
        },
        // 获取详情
        async getTrainActivityInfoFun() {
            var params = {
                id: this.$route.query.id,
            }
            var res = await getTrainActivityInfoApi(params)
            this.form = res
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.$route.query.id) {
                        this.getTrainActivityUpdateFun()
                    } else {
                        this.getTrainActivityCreateFun()
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 新增
        async getTrainActivityCreateFun() {
            var res = await getTrainActivityCreateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '新增成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '新增失败',
                    type: 'error',
                })
            }
        },
        // 更新
        async getTrainActivityUpdateFun() {
            delete this.form.createTime
            delete this.form.publishTime
            var res = await getTrainActivityUpdateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '修改成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '修改失败',
                    type: 'error',
                })
            }
        },
        // 分页插件事件
        callFather(parm) {
            this.getTrainActivitySignUpInfoFun(parm.currentPage, parm.pageSize)
        },
        // 培训封面
        coverClick(value) {
            this.form.activityCover = value
        },
        // 导出报告
        deriveClick() {
            this.download(
                '/doftec/trainActivityUser/list/' + this.$route.query.id,
                this.form.activityName + '导出报名信息.xlsx'
            )
        },
    },
}
</script>

<style scoped>
.app-content {
    width: 1200px;
    margin: 20px auto 20px auto;
}
::v-deep .el-form-item {
    width: 100%;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>
